/* CustomScrollbar.css */
/* This is the custom CSS for the scrollbar */
.scrollbar-thumb {
  background-color: #C0A080; /* Indigo color for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.scrollbar-track {
  background: transparent; /* Transparent track */
}

/* Apply these styles only to Webkit browsers like Chrome, Safari */
::-webkit-scrollbar-thumb {
  background-color: #8B0000;
  border-radius: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}